import { get, post, Delete } from "@/api/config";

// == app version ==
export const AppQuery = (query) => get("v1/version/list" + query);

export const AppCreate = (params) => post("v1/version/create", params);

export const AppRemove = (data) => Delete(`v1/version/delete`, data);


export const AppFileUpload = (params) =>
    post("v1/version/upload", params);

export const testlog = (params) => {
    console.log('this', this);
};