<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>App-安卓管理</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item">
              <label>版本号：</label>
              <el-input
                placeholder="版本号"
                v-model="query.versionNumber"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="x-con-item">
              <label>发布日期：</label>
              <el-date-picker
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="query.dateRange"
              ></el-date-picker>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
          <el-button
            type="primary"
            size="small"
            class="fr"
            @click="dialogShow = true"
            >上传新版本</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="id" label="id" width="120">
            </el-table-column>
            <el-table-column
              prop="versionNumber"
              label="版本号"
              width="150"
            ></el-table-column>
            <el-table-column prop="versionUrl" label="安装包" width="300">
              <template slot-scope="scope">
                <el-link :href="scope.row.versionUrl" target="_blank">{{
                  scope.row.versionUrl
                }}</el-link>
              </template></el-table-column
            >
            <el-table-column prop="releaseTime" label="发布时间" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center" width="280">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-popover
                    placement="top"
                    title="版本更新日志"
                    width="300"
                    trigger="click"
                    :content="scope.row.versionLog"
                  >
                    <el-button slot="reference" size="mini" type="success"
                      >查看版本日志</el-button
                    >
                  </el-popover>
                  <el-link
                    :underline="false"
                    type="success"
                    @click="goDetail(scope.row)"
                  ></el-link>
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goDownload(scope.row.versionUrl)"
                    >下载</el-link
                  >
                  <el-link
                    :underline="false"
                    type="danger"
                    @click="doDelete(scope.row.id)"
                    >删除</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加版本信息面板 -->
    <appPanel
      :dialogShow.sync="dialogShow"
      ref="appPanel"
      @updata="doQuery"
    ></appPanel>
  </div>
</template>

<script>
import { AppQuery, AppCreate, AppRemove } from "@/api/module/app";

export default {
  name: "AppIndex",
  data() {
    return {
      showLoading: false,
      dialogShow: false,
      query: {
        versionNumber: "",
        dateRange: [],
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },
  components: {
    appPanel: () => import("./components/appPanel.vue"),
  },
  created() {
    this.doQuery();
  },
  methods: {
    goDownload(url) {
      window.open(url, "安装包下载");
    },
    // 删除
    doDelete(id) {
      const msg = "<span class='font-danger'>确认要删除本条数据吗？</span>";
      this.$alert(msg, "删除提示", {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: "确定删除",
        center: true,
        callback: (action) => {
          if (action === "confirm") {
            AppRemove({ id }).then((r) => {
              if (r.code === 0) {
                this.doQuery();
                this.$message({
                  type: "success",
                  message: "删除成功",
                  duration: 1300,
                });
              }
            });
          }
        },
      });
    },
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      AppQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    // 设置筛选
    setQueryParams() {
      let query = `?appTag=1&pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.versionNumber) {
        query += `&versionNumber=${this.query.versionNumber}`;
      }
      if (this.query.dateRange.length) {
        query += `&releaseBegin=${this.query.dateRange[0]}&releaseEnd=${this.query.dateRange[1]}`;
      }
      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        dateRange: [],
        versionNumber: "",
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>



